/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */


(function($,sr){

  // debouncing function from John Hann
  // http://unscriptable.com/index.php/2009/03/20/debouncing-javascript-methods/
  var debounce = function (func, threshold, execAsap) {
      var timeout;

      return function debounced () {
          var obj = this, args = arguments;
          function delayed () {
              if (!execAsap) {
                func.apply(obj, args);
              }
              timeout = null;
          }

          if (timeout) {
            clearTimeout(timeout);
          } else if (execAsap) {
            func.apply(obj, args);
          }

          timeout = setTimeout(delayed, threshold || 100);
      };
  };
  // smartresize
  jQuery.fn[sr] = function(fn){  return fn ? this.bind('resize', debounce(fn)) : this.trigger(sr); };

})(jQuery,'smartresize');

(function($) {

var updateViewportDimensions = function () {

	var z,w=window,d=document,e=d.documentElement,g=d.getElementsByTagName('body')[0],x=w.innerWidth||e.clientWidth||g.clientWidth,y=w.innerHeight||e.clientHeight||g.clientHeight;
	if ( x <= 480 ) {
  	z = 'xs';
	} else if ( x > 480 && x <= 767 ) {
  	z = 'sm';
	} else if ( x > 767 && x <= 1024 ) {
  	z = 'md';
	} else if ( x > 1024 && x <= 1455 ) {
  	z = 'lg';
	} else if ( x > 1455 ) {
  	z = 'xl';
	}

	return { width:x,height:y,breakpoint:z };
};

// Use this variable to set up the common and page specific functions. If you
// rename this variable, you will also need to rename the namespace below.

var Innvie = {
	// All pages
	'common': {
		// JavaScript to be fired on all pages
		init: function() {

  		var viewport = updateViewportDimensions();

      function checkToggle( target, triggerSelector, triggerValue, targetShow = true ) {

        var triggerChecked = $( triggerSelector + ':checked' ).val();

        if ( ( triggerChecked === triggerValue && targetShow === true ) || ( triggerChecked !== triggerValue && targetShow !== true ) ) {

          $(target).show();
          $(target).find('input, textarea, select').prop('disabled', false);

        } else {

          $(target).hide();
          $(target).find('input, textarea, select').prop('disabled', true);

        }

      }

      function dateInputFallback() {

        // test whether a new date input falls back to a text input or not
        var dateTest = document.createElement('input');
        dateTest.type = 'date';

        if ( dateTest.type === 'text' ) {

          $('input[type="date"]').each( function() {

            var datePickerConfig = {
              formatSubmit: 'yyyy-mm-dd',
              selectYears: true,
              selectMonths: true
            };

            if ( $(this).attr('data-date-format') ) {
              datePickerConfig.format = $(this).attr('data-date-format')
                .replace('d', 'dd')
                .replace('m', 'mm')
                .replace('y', 'yy')
                .replace('j', 'd')
                .replace('D', 'ddd')
                .replace('l', 'dddd')
                .replace('n', 'm')
                .replace('M', 'mmm')
                .replace('F', 'mmmm')
                .replace('Y', 'yyyy');
            }

            if ( $(this).attr('min') ) {
              datePickerConfig.min = new Date( $(this).attr('min') );
            }

            if ( $(this).attr('max') ) {
              datePickerConfig.max = new Date( $(this).attr('max') );
            }

            var $input = $(this).pickadate(datePickerConfig);
            var $picker = $input.pickadate('picker');

            if ( $(this).val() ) {

              $picker.set('select', new Date( $(this).val() ));

            } else {

              var today = new Date();

              if ( $(this).attr('min') && datePickerConfig.min > today ) {
                $picker.set( 'highlight', datePickerConfig.min );
              } else if ( $(this).attr('max') && datePickerConfig.max < today ) {
                $picker.set( 'highlight', datePickerConfig.max );
              }

            }

          });

        }

      }

      $('[data-trigger]').each( function( index, target ) {

        var triggerSelector;
        var targetShow = $(target).attr('data-show');

        if ( typeof targetShow !== typeof undefined && targetShow !== false ) {

          triggerSelector = $(target).attr('data-trigger');

          checkToggle( target, triggerSelector, targetShow );

          $( triggerSelector ).change( function() {

            checkToggle( target, triggerSelector, targetShow );

          });

        } else {

          var targetHide = $(target).attr('data-hide');

          if ( typeof targetHide !== typeof undefined && targetHide !== false ) {

            triggerSelector = $(target).attr('data-trigger');

            checkToggle( target, triggerSelector, targetHide, false );

            $( triggerSelector ).change( function() {

              checkToggle( target, triggerSelector, targetHide, false );

            });

          }

        }

      });

      var innvieFormInputFocus = function( event, el ) {

        setTimeout(function() {

          var formId = $( el ).closest( 'form' ).attr('id');
          var formContent = innvieFormSettings[formId].formContent;
          var submitButtonContent = innvieFormSettings[formId].submitButton;

          $( el ).parent().prepend( formContent );
          dateInputFallback();
          var panel = $( el ).siblings('.innvie-form-content').children('.innvie-form-accordion-panel');
          $( el ).replaceWith( submitButtonContent ).blur().trigger('touchend');
          panel.velocity('slideDown', { duration: 200 });
          panel.velocity('scroll', { duration: 400, offset: -50 });

          setTimeout(function() {
            $( panel ).find( '*' ).filter( ':input:visible:enabled:not([readonly]):first' ).focus();
          }, 400);

          event.preventDefault();

        }, 10);

      };

      var mobile = false;

      $( '.innvie-form-accordion-trigger' ).on( 'click', function( event ) {
        if ( mobile ) {
          return;
        }
        innvieFormInputFocus( event, $( this ) );
      });

      $( '.innvie-form-accordion-trigger' ).on( 'touchstart', function( event ) {
        mobile = true;
        innvieFormInputFocus( event, $( this ) );
      });

      $( '.innvie-form-accordion-trigger' ).on( 'touchend', function( event ) {
        event.preventDefault();
        event.stopPropagation();
      });

      $( 'form.innvie-form.ajax-enabled' ).on( 'submit', function( event ) {

        event.preventDefault();

        var $button = $( this ).find( 'input[type="submit"]' );

    		if ( $button.hasClass('loader') ) {
    			return false;
    		}

        var $formContent = $( this ).find('.innvie-form-content'),
            formId = $( this ).attr('id'),
            buttonText = $button.val(),
            buttonHeight = $button.height();

        $button.width( buttonHeight ).height( buttonHeight ).val('').prop('disabled', true).addClass('loader');

        $.ajax({
          method: 'POST',
          url: innvieFormSettings[formId].ajaxUrl,
          data: {
            'action' : formId,
            'nonce'  : $button.data('nonce'),
            'submission': $( '#' + formId ).serialize()
          },
          success: function( response ) {
            if ( typeof response.data !== 'undefined' ) {
              $formContent.siblings('.notification').remove();
              $button.removeClass('loader').blur().prop('disabled', false).val( buttonText ).width( '' ).height( '' );
              $formContent.before(response.data);
            }
            if ( response.success === true ) {
              $button.remove();
              $formContent.velocity('slideUp', { duration: 200 }).siblings('.notification').velocity('scroll', { duration: 400, offset: -50 });
            }
          },
          error: function( response ) {
            if ( typeof response.data === 'undefined' ) {
              response.data = '<div id="error-notification" class="notification"><p><strong>Form submission failure. Server error.</strong></p><p>Your message has not been sent. Please try again.</p></div>';
            }
            $formContent.siblings('.notification').remove();
            $button.removeClass('loader').blur().prop('disabled', false).val( buttonText ).width( '' ).height( '' );
            $formContent.before(response.data);
          },
          timeout: 5000
        });

      });

      $( 'form.innvie-form' ).on( 'blur', 'input[type=text], input[type=email], input[type=number], input[type=tel], textarea', function( event ) {

        if ( event.type === 'focusout' || ( event.which !== 0 && !event.ctrlKey && !event.metaKey && !event.altKey ) ) {

          var emailRegex = /(?:[a-z0-9!#$%&'*+\/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+\/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/i;

          if ( $(this).attr('type') === 'email' && $(this).val().length > 0 && !emailRegex.test( $(this).val() ) ) {

            $(this).removeClass('is-valid').addClass('is-invalid');

          } else if ( $(this).attr('required') === 'required' && $(this).val().length === 0 ) {

            $(this).removeClass('is-valid').addClass('is-invalid');

          } else if ( $(this).val().length === 0 ) {

            $(this).removeClass('is-valid').removeClass('is-invalid');

          } else {

            $(this).removeClass('is-invalid').addClass('is-valid');

          }

        }

      });

      $( 'form.innvie-form' ).on( 'focus', 'input[type=text], input[type=email], input[type=number], input[type=tel], textarea', function( event ) {

        $(this).removeClass('is-invalid');

      });

  		$('.page-content p:empty').remove();

			$(".slick").each( function() {

  			var slickSettings = {};

  			if ( $(this).siblings(".carousel-arrow-container").length ) {

    			slickSettings = {
      			appendArrows: $(this).siblings(".carousel-arrow-container").find(".carousel-arrows")
    			};

  			}

  			if ( $(this).hasClass('cover-slider') ) {
    			slickSettings.arrows = false;
    			slickSettings.autoplay = true;
    			slickSettings.autoplaySpeed = 5000;
    			slickSettings.mobileFirst = true;
  			}

  			$(this).slick(slickSettings);

  			if ( $(this).parent().siblings('.controls').length ) {

    			var thisSlick = $(this);

    			$(this).parent().siblings('.controls').find('.control-item').click(function() {
            thisSlick.slick('slickGoTo', $(this).index() );
          });

          $(this).on('beforeChange', function(event, slick, currentSlide, nextSlide){
            var currentControl = $(this).parent().siblings('.controls').find('.control-item:eq(' + nextSlide + ')');
            currentControl.addClass('current-item');
            currentControl.siblings().removeClass('current-item');
          });

  			}

  		});

			var scroll = new SmoothScroll('a[href*="#"]', {
        ignore: 'a[href*="?"]',
        speed: 400
      });

      function checkForOverflowedSubmenus() {

        $('ul.sub-menu').each( function() {

          var offset = $(this).parent().offset();
          $(this).removeAttr('style');

          if ( offset.left + $(this).outerWidth() + 20 > viewport.width ) {

            var newLeft = parseInt( $(this).css('left') ) - ( offset.left + $(this).outerWidth() - viewport.width ) - 20;
            $(this).css({ left: newLeft + 'px' });

          }

        });

      }

      checkForOverflowedSubmenus();

			var menuToggle = $("#header-nav-toggle").unbind();

			menuToggle.on("click", function(e) {

				e.preventDefault();

				$('#header-nav').slideToggle(300, function(){
					if($('#header-nav').is(":hidden")) {
						$('#header-nav').removeAttr("style");
					}
				});

				$(this).toggleClass('opened');

				if ( $('#header-nav').hasClass( 'open' ) ) {
					$('#header-nav').toggleClass('open');
				} else {
					setTimeout( function() { $('#header-nav').toggleClass('open'); }, 200 );
				}

			});

      function toggleSubmenu(el) {

				if ( $(el).parent().hasClass( 'submenu-open' ) ) {

  				$(el).velocity('slideUp', { duration: 300 }).parent().removeClass('submenu-open');
					setTimeout( function() { $(el).css('display', ''); }, 400 );

				} else {

  				$(el).velocity('slideDown', { duration: 300 });
					setTimeout( function() { $(el).parent().addClass('submenu-open'); }, 200 );

				}

      }

  		$('header#page-header .submenu-arrow').each( function() {

  			var subMenuToggle = $(this).unbind();

  			subMenuToggle.on("click", function(e) {

  				e.preventDefault();
  				toggleSubmenu( $(this).siblings('.sub-menu') );

  			});

  		});

  		$('header#page-header .menu-item.menu-item-has-children > a').click( function(event) {

    		if ( !$(this).parent().hasClass('submenu-open') && $('#header-nav-toggle').is(':hidden') ) {

      		event.preventDefault();

      		$(this).parent().addClass('submenu-open');
      		$(this).parent().siblings().removeClass('submenu-open');

    		}

  		});

  		$(document).click(function(event){

    		if ( !$(event.target).parent().hasClass('menu-item') && $('#header-nav-toggle').is(':hidden') ) {

      		$('header#page-header .menu-item.menu-item-has-children').removeClass('submenu-open');

    		}

      });

      $(window).smartresize(function(){

        var oldBreakpoint = viewport.breakpoint;
      	viewport = updateViewportDimensions();

      	if ( $.inArray( oldBreakpoint, [ 'xs', 'sm' ] ) !== -1 && $.inArray( viewport.breakpoint, [ 'xs', 'sm' ] ) === -1 ) {
      		$('header#page-header .menu-item.menu-item-has-children').removeClass('submenu-open');
					$('header#page-header .menu-item.menu-item-has-children .sub-menu').css('display', '');
      	}

        checkForOverflowedSubmenus();

      });

			$(document).on('click', '.blocks-gallery-item a', lity);

      $('a').filter(function() {
        return $(this).text() === "Download";
      }).html ('<svg version="1.1" viewBox="0 0 92 92" xmlns="http://www.w3.org/2000/svg"><path d="M91 44.4c-.6 8.6-7.2 17.8-20.3 17.8h-9.1c-2.2 0-4-1.9-4-4.1 0-2.2 1.8-4.1 4-4.1 0 0 3.1.1 9.1.1 8 0 12-5.1 12.4-10.3.4-5.3-3-11.2-11.3-12.4-1.1-.2-2.1-.8-2.7-1.7-.6-.9-.8-2.1-.6-3.2 1.1-4.5-.1-8.1-3-9.8-2.5-1.4-6.5-1.4-9.9 1.9-.9.9-2.2 1.3-3.4 1.1-1.2-.2-2.3-1-2.9-2.1C43.7 7.1 35 6.5 29.3 9.2c-5.5 2.6-9.9 9.1-6.5 17.3.4 1 .4 2.2-.1 3.2s-1.4 1.8-2.4 2.1C8.5 35.5 8.8 43.3 9.1 45.5c.6 4.3 4 8.6 9.3 8.6h12.2c2.2 0 4 1.8 4 4s-1.8 3.9-4 3.9H18.4C9.8 62 2.5 55.6 1.2 46.7c-1-6.9 1.8-16.1 12.7-21C11.5 15 17.5 6 25.7 2.1c8.4-4 20.2-2.9 28.1 8 4.9-2.7 10.7-2.9 15.5-.2 4.1 2.3 7.8 7.2 7.5 14.5 10.4 3.2 14.7 12.2 14.2 20zM59.7 68.6L50 78.4l-.1-36.3c0-2.2-1.8-4.1-4-4.1s-4 1.9-4 4.1l.1 36.2-9.7-9.7c-1.6-1.6-4.1-1.6-5.7 0-1.6 1.6-1.6 4.1 0 5.7l16.5 16.6c.8.8 1.8 1.2 2.8 1.2 1 0 2.1-.4 2.8-1.2l16.5-16.5c1.6-1.6 1.6-4.1 0-5.7-1.4-1.6-3.9-1.6-5.5-.1z"/></svg>').css('padding', '0.375rem 0.375rem 0.25rem');

      function adjustHeight(el, minHeight) {

        // compute the height difference which is caused by border and outline
        var outerHeight = parseInt(window.getComputedStyle(el).height, 10);
        var diff = outerHeight - el.clientHeight;

        // set the height to 0 in case of it has to be shrinked
        el.style.height = 0;

        var scrollHeight = el.scrollHeight;

        // set the correct height
        // el.scrollHeight is the full height of the content, not just the visible part
        el.style.height = ( scrollHeight + diff ) + 'px';

      }

      // we use the "data-adaptheight" attribute as a marker
      var textAreas = [].slice.call(document.querySelectorAll('textarea'));

      // iterate through all the textareas on the page
      textAreas.forEach(function(el) {

        el.style.overflowY = 'hidden'; // we don't need any scrollbars, do we? :)
        var minHeight = el.scrollHeight; // the minimum height initiated through the "rows" attribute

        el.addEventListener('input', function() {
          adjustHeight(el, 0);
        });

        window.addEventListener('resize', function() {
          adjustHeight(el, 0);
        });

        adjustHeight(el, minHeight);

      });

      $('.event-info-toggle').click( function(){

        var moreInfoBox = $(this).parent().next('.event-more-info');

        if ( moreInfoBox.is(':visible') ) {

          moreInfoBox.velocity('slideUp', { duration: 200 });

        } else {

          moreInfoBox.velocity('slideDown', { duration: 200 });

        }

      });

      dateInputFallback();

      $( '.close-header-notification' ).on( 'click', function( event ) {

        date = new Date();
        date.setTime( date.getTime() + (60*60*1000) ); // 1 hour later
        document.cookie = "homePageNotification=hide; expires=" + date.toGMTString() + "; path=/";

        $( this ).closest('#home-page-notification').velocity('slideUp', { duration: 400 });

      });

		},
		// JavaScript to be fired on all pages, after page specific JS is fired
		finalize: function() {

  		cssVars();

      $('body').removeClass('no-transition');

  		$('.metaslider img').removeAttr('title');

  		$('.slick-slideshow').each( function() {

    		var slickSettings = $(this).data();

    		$.each( slickSettings, function(k, v) {

      		var camelCased = k.replace(/-([a-z])/g, function (g) { return g[1].toUpperCase(); });
      		delete slickSettings[k];
          slickSettings[camelCased] = v;

    		});

    		$(this).slick(slickSettings);

  		});

  		$('table.wp-block-table, figure.wp-block-table > table').each( function() {

    		var table = $(this);

    		if ( $(table).has('thead').length > 0 ) {

  	  		$(table).find('thead th').each( function( index ) {

        		var columnHeader = $(this).html();
        		var columnIndex = index;

    	  		$(table).find('tbody tr').each( function() {

      	  		$(this).find('td').eq(columnIndex).prepend('<h6 class="table-header-mobile no-margin">' + columnHeader + '</h6>');

    	  		});

  	  		});

  	  		$(table).addClass('has-responsive-headers');

	  		}

  		});

      // Scroll to screen height if previously saved
      var previousHeight = localStorage.getItem( 'pageHeight' );
      if ( previousHeight && previousHeight !== 'null' ) {
        window.scrollTo(0, previousHeight );
        localStorage.setItem( 'pageHeight', null );
      }

      // Saves scroll height on submit
      $('form').on('submit', function(){
        localStorage.setItem( 'pageHeight', $(window).scrollTop() );
      });

		}
	},
	// Home page
	'home': {
		// JavaScript to be fired on the home page
		init: function() {},
		// JavaScript to be fired on the home page, after the init JS
		finalize: function() {}
	},
	// About us page
	'about_us': {
		// JavaScript to be fired on the about us page
		init: function() {},
		// JavaScript to be fired on the about us page, after the init JS
		finalize: function() {}
	}
};

// The routing fires all common scripts, followed by the page specific scripts.
// Add additional events for more control over timing e.g. a finalize event
var UTIL = {
	fire: function(func, funcname, args) {
		var fire;
		var namespace = Innvie;
		funcname = (funcname === undefined) ? 'init' : funcname;
		fire = func !== '';
		fire = fire && namespace[func];
		fire = fire && typeof namespace[func][funcname] === 'function';

		if (fire) {
			namespace[func][funcname](args);
		}
	},
	loadEvents: function() {
		// Fire common init JS
		UTIL.fire('common');

		// Fire page-specific init JS, and then finalize JS
		$.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
			UTIL.fire(classnm);
			UTIL.fire(classnm, 'finalize');
		});

		// Fire common finalize JS
		UTIL.fire('common', 'finalize');
	}
};

// Load Events
$(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
